import { forwardRef } from "react";
import { ClassNames } from "@emotion/react";
import { clsx } from "clsx";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, disabled, type = "button", role, ...props }, ref) => {
    return (
      <button
        type={type}
        disabled={disabled}
        className={clsx(
          `
          btn 
          bg-gray-900
          text-gray-100
          hover:bg-gray-800
          dark:bg-gray-100 
          dark:text-gray-800 
          dark:hover:bg-white
          w-auto 
          bg-black
          border
          border-transparent
          disabled:cursor-not-allowed 
          disabled:opacity-50
          text-white
          font-semibold
          hover:opacity-75
          transition
        `,
          disabled && "opacity-75 cursor-not-allowed",
          className
        )}
        ref={ref}
        role={role}
        {...props}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";

export default Button;
