import React, { useState } from "react";
import Image from "next/image";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <header
      className={`flex w-full items-center bg-[#F8F8F8] dark:bg-gray-900 lg:px-[60px] lg:py-0 px-[20px] py-0 dark:bg-[#111827] dark:text-white`}
    >
      <div className="container">
        <div className="relative -mx-4 flex items-center justify-between">
          <div className="w-60 max-w-full px-4">
            <a href="/#" className="block w-full py-5">
              <Image
                src="/images/logo.png"
                width={500}
                height={500}
                alt="Picture of the author"
              />
            </a>
          </div>
          <div className="flex w-full items-center justify-between px-4">
            <div>
              <button
                onClick={() => setOpen(!open)}
                id="navbarToggler"
                className={` ${
                  open && "navbarTogglerActive"
                } absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden`}
              >
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color bg-black dark:bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color bg-black dark:bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color bg-black dark:bg-white"></span>
              </button>
              <nav
                // :className="!navbarOpen && 'hidden' "
                id="navbarCollapse"
                className={`absolute right-4 top-full w-full max-w-[250px] rounded-lg px-6 py-5 shadow dark:bg-gray-900-2 lg:static lg:block lg:w-full lg:max-w-full lg:shadow-none lg:dark:bg-transparent ${
                  !open && "hidden"
                } `}
              >
                <ul className="block lg:flex">
                  <ListItem NavLink="/#">Home</ListItem>
                  <ListItem NavLink="/#">Business Financing</ListItem>
                  <ListItem NavLink="/#">About Us</ListItem>
                </ul>
              </nav>
            </div>
            <div className="hidden justify-end pr-16 sm:flex flex lg:pr-0">
              <a
                href="/signin"
                className="lg:px-7 lg:py-3 text-base font-medium text-dark hover:text-primary no-underline text-black	dark:text-white dark:hover:text-blue"
              >
                Sign in
              </a>

              <a
                href="/signup"
                className="rounded-md bg-primary lg:px-7 lg:py-3 text-base font-medium hover:bg-primary/90 no-underline text-black dark:text-white dark:hover:text-blue"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;

const ListItem = ({ children, NavLink }) => {
  return (
    <>
      <li>
        <a
          href={NavLink}
          className="flex py-2 text-base font-medium text-body-color hover:text-dark dark:text-white dark:hover:text-white lg:ml-12 lg:inline-flex no-underline text-black	"
        >
          {children}
        </a>
      </li>
    </>
  );
};
