"use client";

import Navbar from "../components/ui/Navbar";
import HomePage from "../components/ui/Homepage";

export default function Home() {
  return (
    <div>
      <Navbar />
      <HomePage />
    </div>
  );
}
