import React, { useState } from "react";
import Image from "next/image";
import Button from "../Button";

const HomePage = () => {
  return (
    <div className="flex lg:px-[60px] lg:pt-[60px] bg-[#F8F8F8] dark:bg-[#111827]">
      <div className="flex-1 basis-1/2 lg:px-[80px] px-4">
        <h1 className="text-[64px] leading-[59px] w-3/4 font-bold">
          Fuel Your Growth and Potential
        </h1>
        <p className="py-[28px] w-3/4 ">
          Partner with us to achieve your business goals. Through our
          passion-driven approach, we aspire to be the catalyst that propels
          SMEs into new realms of prosperity and possibilities.
        </p>
        <Button><a href="/signup">Apply for a loan</a> </Button>
        <div className="mt-20">
          <span>Some Of Our Clients</span>
          <div className="flex gap-3 mt-2.5">
            <Image
              src="/images/oracle.svg"
              height={0}
              width={0}
              alt="Picture of the author"
              style={{ width: "auto", height: "20px" }}
            />
            <Image
              src="/images/intel.svg"
              height={0}
              width={0}
              alt="Picture of the author"
              style={{ width: "auto", height: "20px" }}
            />
            <Image
              src="/images/logitech.svg"
              height={0}
              width={0}
              alt="Picture of the author"
              style={{ width: "auto", height: "20px" }}
            />
          </div>
        </div>
      </div>
      <div className="flex-1 basis-1/2 justify-center hidden lg:flex">
        <Image
          src="/images/home-img.png"
          width={500}
          height={515}
          alt="Picture of the author"
          style={{ width: "auto", height: "515px" }}
        />
      </div>
    </div>
  );
};

export default HomePage;
